/* GLOBALS */
img {
  max-width: 100%;
}
/* UTILITIES */
.container {
  width: 80%;
  margin: 0 auto;
}

.mt-5px {
  margin-top: 5px;
}
.mt-15px {
  margin-top: 15px;
}
.mt-25px {
  margin-top: 25px;
}
.mb-15px {
  margin-bottom: 15px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-25px {
  margin-bottom: 25px;
}
.mb-5px {
  margin-bottom: 5px;
}
.m-auto {
  margin-right: auto;
  margin-left: auto;
}

.font-bold {
  font-weight: bold;
}
.font-pacifico {
  font-family: 'Pacifico';
}
.font-16px {
  font-size: 16px;
}
.font-14px {
  font-size: 14px;
}
.font-12px {
  font-size: 12px;
}
.font-10px {
  font-size: 10px;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-uppercase {
  text-transform: uppercase;
}

.w-10 {
  width: 10%;
}
.w-30 {
  width: 30%;
}
.w-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.mw-100 {
  max-width: 100%;
}

.h-200px {
  height: 200px;
}

.p-2px {
  padding: 2px;
}
.pb-10px {
  padding-bottom: 10px;
}

.border {
  border: 1px solid #000;
}
.border-bottom {
  border-bottom: 1px solid #000;
}

.d-block {
  display: block;
}

.line-height-1-5 {
  line-height: 1.5;
}

.indentation {
  text-indent: 50px;
}

/* GRID */
.grid {
  position: relative;
  height: auto;
}
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}
.col {
  margin-bottom: 1.5px;
}
.col-1 {
  width: 8.3%;
  height: 100%;
  float: left;
}
.col-2 {
  width: 16.6%;
  height: 100%;
  float: left;
}
.col-3 {
  width: 25%;
  height: 100%;
  float: left;
}
.col-4 {
  width: 33.3%;
  height: 100%;
  float: left;
}
.col-5 {
  width: 41.6%;
  height: 100%;
  float: left;
}
.col-6 {
  width: 50%;
  height: 100%;
  float: left;
}
.col-7 {
  width: 58.3%;
  height: 100%;
  float: left;
}
.col-8 {
  width: 66.6%;
  height: 100%;
  float: left;
}
.col-9 {
  width: 75%;
  height: 100%;
  float: left;
}
.col-10 {
  width: 83.3%;
  height: 100%;
  float: left;
}
.col-11 {
  width: 91.6%;
  height: 100%;
  float: left;
}
.col-12 {
  width: 100%;
  height: 100%;
  float: left;
}
/* COMPONENTS */
.logo {
  width: 200px;
}

/* TABLE */
.table {
  width: 100%;
  letter-spacing: 0.5px;
  line-height: 1.2;
  border: none;
}
.table th {
  padding: 1px;
  border: thin solid #000;
  border-right: none;
}
.table td {
  height: 20px;
  padding: 1px;
  border: thin solid #000;
  border-top: none;
  border-right: none;
}
.table th:last-of-type,
.table td:last-of-type {
  border-right: thin solid #000;
}

.inside-table {
  border-bottom: none;
}
.inside-table th,
.inside-table td {
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 1px;
}
.inside-table th:last-of-type,
.inside-table td:last-of-type {
  border-right: none;
}
